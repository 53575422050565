body, html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--ui-colors-bg-base);
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  width: 400px;
  min-height: 300px;
  height: min-content;
  padding: var(--ui-spacing-xl);
  background-color: var(--ui-colors-bg-base-secondary);
  border-radius: var(--ui-radius-l);
  display: flex;
  flex-direction: column;
}

.auth-widget {
  width: 100%;
}

.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
